$transparant: transparent;
$color-black: #1A1A1A;
$color-black-hover: #363636;
$color-gray-light: #E7E7E7;
$color-red: #bc1f34;
$color-red-text: #ffa3a3;
$color-white: #FFFFFF;
$color-white-soft: #d9d9d9;

$height-navbar-desktop: 100px;

$font-size-body-extrasmall: 0.75rem;
$font-size-body-small: 0.875rem;
$font-size-default: 1rem;
$font-size-body: 1.25rem;
$font-size-emp: 1.375rem;
$font-size-title: 1.75rem;
$font-size-big: 2.25rem;
$font-size-large: 2.75rem;
$font-size-huge: 3.5rem;
$font-size-giant: 4.5rem;
